import { deepEqual } from "@kikoff/utils/src/object";

import useConditionEffect from "./useConditionEffect";
import usePrevious from "./usePrevious";

export default function useDeepEffect(
  callback: (deps: React.DependencyList) => ReturnType<React.EffectCallback>,
  deps: React.DependencyList
) {
  const lastDeps = usePrevious(deps);

  useConditionEffect(() => {
    if (callback) return callback(lastDeps);
  }, !deepEqual(deps, lastDeps));
}

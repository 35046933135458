import React, { useEffect, useRef } from "react";

export default function useConditionEffect(
  callback: React.EffectCallback,
  condition: boolean
) {
  const ref = useRef(false);
  if (condition) ref.current = !ref.current;

  useEffect(callback, [ref.current]);
}

useConditionEffect.firstOnly = (callback, condition) => {
  const ref = useRef(true);

  useConditionEffect(callback, condition && ref.current);
  if (condition) ref.current = false;
};

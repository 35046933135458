import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import gsap from "gsap";
import { FluentResource } from "@fluent/bundle";
import { LocalizationProvider, Localized } from "@fluent/react";

import useCombinedRefs from "@kikoff/hooks/src/useCombinedRefs";
import useDeepEffect from "@kikoff/hooks/src/useDeepEffect";
import useObjectState from "@kikoff/hooks/src/useObjectState";
import { promiseDelay } from "@kikoff/utils/src/general";
import { deepEqual, objectIndexOf, pick } from "@kikoff/utils/src/object";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import { combineClasses } from "@kikoff/utils/src/string";

import { CheckPathAnimation } from "@component/animations/svg_animations";
import { ErrorPanel } from "@component/errors";
import { GenericSpinner } from "@component/loading_indicators/loading_indicators";
import {
  OnboardingState,
  setCurrentStep,
  setStepMap,
  updateOnboardingState,
} from "@feature/onboarding";
import { updateUserState } from "@feature/user";
import { track } from "@util/analytics";
import { Events } from "@util/events";
import { newReactLocalization } from "@util/l10n";

import styles from "./form.module.scss";

const RESOURCES = {
  en: new FluentResource(`back = Back
next = Next`),
  es: new FluentResource(`back = Atrás
next = Próximo`),
};

/**
 * Builds a form component
 *
 * TODO: [REFACTOR] Separate onboarding form functionality into onboarding component
 * TODO: Define type properly
 * TODO: Check validation in initial render for autofill
 *
 * @param {Object} props Contains component properties
 * @param {Array} props.fields Array of fields to be displayed in the form
 *        Whether or not the form is enabled is determined by field validation props
 *        "form" prop is captured by form before rendering
 * @param {Object} [props.submit] Contains all submission data
 * @param {Function} [props.submit.onSubmit] Overrides default form submission behavior
 * @param {Array} [props.submit.requests] Request objects array that is passed to webRPC method, @see @kikoff/utils/src/proto
 * @param {Object} [props.submit.request] Single request object, handled the same way as requests as a single element array
 * @param {Function} [props.submit.before] Request submission middleware
 * @param {Function} [props.completedFields] Methods for building onboarding fieldDefaults
 * @param {Object} [props.logDetails] hash of additional Amplitude event data
 * @param {String|React.Component} [props.error] Error message content
 * @param {Object} [props.buttonContent] Content for button depending on button status
 * @returns {React.Component} Onboarding form component
 * @deprecated Use useForm instead for form handling
 */
const Form = forwardRef<HTMLDivElement, { [key: string]: any }>(
  (
    {
      submit = {},
      stepNumber,
      expand = false,
      disable = false,
      logDetails = {},
      container = {},
      dynamic = false,
      children = [],
      reset,
      ...props
    },
    _ref
  ) => {
    const dispatch = useDispatch();

    const onboarding = useSelector((state) => state.onboarding);
    const l10n = newReactLocalization(RESOURCES);

    const [button, setButton] = useState("initial");
    const [error, setError] = useObjectState<{
      message: string;
      show: boolean;
      fields?: string[];
      check?(): boolean;
    }>({
      message: "",
      show: false,
    });
    const [renderFields, setRenderFields] = useState(expand);
    const [resetter, setResetter] = useState(false);
    const [tl] = useState(
      gsap.timeline({
        paused: true,
        defaults: { duration: 0.3, ease: "sine.inOut" },
        onStart: () => setRenderFields(true),
        onReverseComplete: () => setRenderFields(false),
      })
    );

    const ref = useRef(null);
    const refs = useCombinedRefs(ref, _ref);

    useEffect(() => {
      const root = ref.current;
      tl.to(
        root.querySelector(`.${styles.summary}`),
        {
          opacity: 0,
        },
        0
      )
        .to(
          root.querySelector(`.${styles["onboarding-form"]}`),
          {
            height: root.querySelector(`.${styles["onboarding-form"]}`)
              .scrollHeight,
            opacity: 1,
          },
          0
        )
        .set(root.querySelector(`.${styles["onboarding-form"]}`), {
          height: "initial",
          maxHeight:
            root.querySelector(`.${styles["onboarding-form"]}`).scrollHeight +
            500,
        });
      expand && tl.seek("-=0", false);
    }, []);

    useEffect(() => {
      expand ? tl.play() : tl.reverse();

      if (!expand) setResetter(!resetter);
    }, [expand]);

    useEffect(() => {
      if (props.error)
        typeof props.error === "string"
          ? setError.replace({ message: props.error, show: true })
          : setError.replace({ show: true, ...props.error });
    }, [props.error]);

    const fieldDefaults =
      props.fieldDefaults ||
      (onboarding.stepMap &&
        props.completedFields &&
        props.completedFields.build(
          props.completedFields.get(onboarding.stepMap[stepNumber])
        ));
    const [valid, setValid] = useState<boolean[]>();

    const mustValidate = [];

    children = React.Children.toArray(children).reduce(function reducer(
      acc: React.ReactNodeArray,
      child: React.ReactElement
    ) {
      if (child) {
        if (child.props) {
          // eslint-disable-next-line no-param-reassign
          child = {
            ...child,
            props: {
              ...child.props,
              // className:
              ...(typeof child.type !== "string" && {
                injection: {
                  setError,
                },
              }),
            },
          };
          const _props = child.props;
          let inputIndex;
          if (_props.validate) {
            mustValidate.push(child);
            inputIndex = mustValidate.length - 1;
          }
          if (_props.name) {
            const {
              onChange,
              validate,
              form: { initialFormat = _props.format || ((str) => str) } = {},
            } = _props;
            delete _props.form;

            // eslint-disable-next-line no-param-reassign
            Object.assign(_props, {
              className: combineClasses(
                "form-input",
                "a",
                styles["form-input"],
                _props.className
              ),
              ...(fieldDefaults &&
                fieldDefaults[_props.name] && {
                  defaultValue: initialFormat(fieldDefaults[_props.name]),
                }),
              ...(_props.validate && {
                validate: (value) =>
                  (fieldDefaults &&
                    fieldDefaults[_props.name] &&
                    value === fieldDefaults[_props.name]) ||
                  validate?.(value),
              }),
              onChange(e, ...args) {
                onChange?.(e, ...args);
                if (
                  (!error.fields || error.fields.includes(_props.name)) &&
                  (!error.check || error.check())
                ) {
                  setError({ show: false });
                }
                if (_props.validate && valid) {
                  const _valid = [
                    ...valid.slice(0, inputIndex),
                    _props.validate(e.target.value) as boolean,
                    ...valid.slice(inputIndex + 1),
                  ];
                  if (!deepEqual(valid, _valid)) setValid(_valid);
                }
              },
            });
          }
          if (_props.children) {
            _props.children =
              _props.children instanceof Array
                ? _props.children.reduce(reducer, [])
                : reducer([], _props.children);
          }
        }
        return acc.concat(child);
      }
      return acc;
    },
    []);

    useEffect(() => {
      setError({ show: false });
      setValid(
        mustValidate.map(
          (field) => !!(fieldDefaults && fieldDefaults[field.props.name])
        )
      );
    }, [resetter]);

    // Resize validInputs array when inputs change
    if (dynamic) {
      useDeepEffect(
        (lastValue) => {
          if (lastValue)
            setValid((_valid) => {
              let offset = 0;
              for (let i = 0; i < mustValidate.length; i++) {
                const field = mustValidate[i];
                const index = objectIndexOf(lastValue.slice(i + offset), {
                  name: field.props.name,
                  key: field.key,
                });
                if (index === -1) {
                  offset -= 1;
                  _valid.splice(
                    i,
                    0,
                    !!(fieldDefaults && fieldDefaults[field.props.name])
                  );
                } else {
                  offset += index;
                  _valid.splice(i, index);
                }
              }
              return _valid.slice(0, mustValidate.length);
            });
        },
        mustValidate.map(({ props: { name }, key }) => ({ name, key }))
      );
    }

    const enabled =
      valid?.reduce((res, validField) => res && validField, true) &&
      !error.show &&
      !disable;

    function defaultSubmitHandler(e) {
      e.preventDefault();
      if (enabled) {
        const formObj = Object.fromEntries(
          new FormData(e.target).entries()
        ) as Record<string, string>;
        for (const key of Object.keys(formObj)) {
          formObj[key] = formObj[key].trim();
        }

        if (logDetails.name) {
          const namespace = logDetails.namespace
            ? `${logDetails.namespace}: `
            : "";
          const message = `${namespace}submit ${logDetails.name}`;
          const details =
            typeof logDetails.props === "function"
              ? logDetails.props({ formObj })
              : logDetails.props;

          track(message as keyof Events, details);
        }

        const requests = submit.requests || [submit.request];

        const sendRequest = async () => {
          setButton("pending");

          let pollCount = 0;
          const defaultStatusHandlers: {
            SUCCESS(data): Promise<void>;
            FAILED(data): void;
            UNKNOWN?(data): void;
            WAITING(data): Promise<unknown>;
          } = {
            async SUCCESS(data) {
              (document.activeElement as HTMLInputElement).blur();
              const stepMap: OnboardingState["stepMap"] =
                data.onboardingStatus.steps;
              let firstIncomplete = stepMap
                .map((step) => !!step.complete)
                .indexOf(false);
              if (firstIncomplete === -1) {
                firstIncomplete = stepMap.length;
              }
              setButton("success");

              await promiseDelay(500);

              dispatch(
                updateOnboardingState({
                  completedSteps: firstIncomplete,
                  currentStep: Math.min(
                    onboarding.currentStep + 1,
                    firstIncomplete
                  ),
                })
              );
              dispatch(setStepMap([...stepMap, { name: "review" }]));

              setTimeout(() => setButton("initial"), 1000);
            },
            FAILED: handleFailedStatus(),
            WAITING() {
              if (pollCount > 20)
                throw {
                  message: "Request timed out, please try again",
                };
              pollCount++;
              return promiseDelay(1000, "WAITING");
            },
          };
          defaultStatusHandlers.UNKNOWN = defaultStatusHandlers.FAILED;

          try {
            for (let i = 0; i < requests.length; i++) {
              const request = requests[i];
              // eslint-disable-next-line no-await-in-loop
              const result = await request.service[request.method](
                request.buildProto?.(formObj) || {}
              )
                .then((response) => {
                  if (request.method === "login") {
                    dispatch(
                      updateUserState({ mfaRequired: response.mfaRequired })
                    );
                  }

                  return response;
                })
                .then(
                  handleProtoStatus({
                    ...defaultStatusHandlers,
                    ...(request.statusHandlers ||
                      request.getStatusHandlers?.({
                        defaultStatusHandlers,
                        setButton,
                        injection: props.injection,
                        dispatch,
                        onboarding,
                        formObj,
                      })),
                  })
                );
              if (result === "WAITING") i--;
            }
          } catch (err) {
            setButton("fail");
            setError.replace(
              pick(err, { message: "", show: true, fields: undefined }) as {
                message: string;
                show: boolean;
              }
            );
          }
        };

        if (
          props.completedFields &&
          (() => {
            const fieldValues = props.completedFields.build(
              requests[0].buildProto(formObj)
            );
            return Object.entries(fieldDefaults).reduce(
              (acc, [key, value]) => acc && fieldValues[key] === value,
              true
            );
          })() &&
          onboarding.currentStep < onboarding.completedSteps
        ) {
          dispatch(
            setCurrentStep(
              Math.min(onboarding.currentStep + 1, onboarding.completedSteps)
            )
          );
        } else if (submit.before)
          try {
            submit.before(sendRequest, {
              formObj,
              setError,
              setButton,
              injection: props.injection,
            });
          } catch (err) {
            setButton("fail");
            setError.replace(
              pick(err, { message: "", show: true, fields: undefined }) as {
                message: string;
                show: boolean;
              }
            );
          }
        else sendRequest();
      }
    }

    return (
      <div
        {...container}
        className={combineClasses(
          styles["form-component-container"],
          container.className
        )}
        ref={refs}
      >
        <LocalizationProvider l10n={l10n}>
          <div className={`summary ${styles.summary}`}>
            {props.summary ||
              (props.enabled &&
                props.format &&
                fieldDefaults &&
                props.format(fieldDefaults))}
          </div>
          <form
            className={combineClasses(
              styles["onboarding-form"],
              props.className,
              props.step
            )}
            style={{
              height: props.collapsedSize,
              ...props.style,
            }}
            onSubmit={submit && (submit.onSubmit || defaultSubmitHandler)}
          >
            <div
              className={styles.padding}
              style={{ padding: props.padFields === false ? "" : "30px 0" }}
            >
              {props.title && (
                <header className={styles.title}>{props.title}</header>
              )}

              <div
                className={styles.fields}
                style={{
                  minHeight:
                    props.fieldsMinHeight ||
                    (props.padFields === false ? "" : "200px"),
                }}
              >
                {renderFields && children}
              </div>
              <div className={styles["error-wrapper"]}>
                <ErrorPanel
                  // @ts-expect-error
                  message={error.message}
                  show={error.show}
                  maxWidth={400}
                  defaultExpanded
                  onClose={() => setError({ show: false })}
                />
              </div>
              {/* Action buttons are reversed so make submit button first tab on the right */}
              <div className={styles.actions}>
                {props.button?.({
                  enabled,
                  content: pick(submit.buttonContent, {
                    initial: l10n.getString("next"),
                    pending: <GenericSpinner color="#fff" size="1.3em" />,
                    success: <CheckPathAnimation size="1.5em" />,
                    fail: "Try again",
                  })[button],
                }) || (
                  <button
                    type="submit"
                    className={combineClasses(styles["form-submit"], {
                      enabled,
                    })}
                    disabled={["pending", "success"].includes(button)}
                    style={{
                      background: enabled ? "var(--inverse)" : "#eee",
                      cursor: enabled ? "pointer" : "initial",
                    }}
                    data-cy={`next-${onboarding.currentStep}`}
                  >
                    {
                      pick(submit.buttonContent, {
                        initial: l10n.getString("next"),
                        pending: <GenericSpinner color="#fff" size="1.3em" />,
                        success: <CheckPathAnimation size="1.5em" />,
                        fail: "Try again",
                      })[button]
                    }
                  </button>
                )}
                {props.hasBack && (
                  <button
                    type="button"
                    className={styles.previous}
                    onClick={
                      props.onBack ||
                      (() =>
                        dispatch(setCurrentStep(onboarding.currentStep - 1)))
                    }
                  >
                    <Localized id="back">Back</Localized>
                  </button>
                )}
              </div>
            </div>
          </form>
        </LocalizationProvider>
      </div>
    );
  }
);

export default Form;
